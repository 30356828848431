<template>
	<div>
		<b-modal
			id="modal-account-statement-total-withdrawal"
			cancel-variant="outline-secondary"
			ok-only
			ok-title="Ok"
			centered
			size="xl"
			:title="total.des"
		>
			<b-card
				v-if="accountStatementTotalWithdrawalDetail"
				no-body
				class="border"
			>
				<b-table
					ref="refAccountStatementTotalWithdrawal"
					class="position-relative table-white-space mb-0"
					:items="accountStatementTotalWithdrawalDetail"
					responsive
					:fields="tableColumns"
					primary-key="index"
					:sort-by.sync="sortBy"
					show-empty
					empty-text="No matching records found"
					:sort-desc.sync="isSortDirDesc"
				>
					<template #cell(index)="data">
						{{ perPage * (currentPage - 1) + data.index + 1 }}
					</template>

					<template #cell(reference)="data">
						<b-link>
							{{ data.item.reference }}
						</b-link>
					</template>

					<!-- Column: User -->
					<template #cell(username)="data">
						{{ data.item.user.username }}
					</template>

					<template #cell(amount)="data">
						{{ numberFormat(data.item.amount) }}
					</template>

					<template #cell(paymentType)="data">
						n/a
					</template>

					<template #cell(currency)="data">
						VND
					</template>

					<template #cell(netAmount)="data">
						{{ data.item.netAmount ? numberFormat(data.item.netAmount) : 'n/a' }}
					</template>

					<!-- Column: Status -->
					<template #cell(status)="data">
						<div :class="`text-capitalize align-items-center d-flex text-${resolveWithdrawalStatusVariant(data.item.stat)}`">
							<span :class="`dot bg-${resolveWithdrawalStatusVariant(data.item.stat)}`" />{{ resolveWithdrawalStatusStyle(data.item.stat) }}
						</div>
					</template>

					<template #cell(createdAt)="data">
						{{ formatDateTime(data.item.created_at).replace('.000000Z', '') }}
					</template>

					<template #cell(updatedAt)="data">
						{{ formatDateTime(data.item.updated_at).replace('.000000Z', '') }}
					</template>

				</b-table>
				<div class="p-2">
					<b-row>

						<b-col
							cols="12"
							sm="6"
							class="d-flex align-items-center justify-content-center justify-content-sm-start"
						>
							<span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>
						</b-col>
						<!-- Pagination -->
						<b-col
							cols="12"
							sm="6"
							class="d-flex align-items-center justify-content-center justify-content-sm-end"
						>

							<b-pagination
								v-model="currentPage"
								:total-rows="totalaccountStatementTotalWithdrawal"
								:per-page="perPage"
								first-number
								last-number
								class="mb-0 mt-1 mt-sm-0"
								prev-class="prev-item"
								next-class="next-item"
							>
								<template #prev-text>
									<feather-icon
										icon="ChevronLeftIcon"
										size="18"
									/>
								</template>
								<template #next-text>
									<feather-icon
										icon="ChevronRightIcon"
										size="18"
									/>
								</template>
							</b-pagination>

						</b-col>

					</b-row>
				</div>
			</b-card>
		</b-modal>
	</div>
</template>

<script>
import {
	BButton, BModal, VBModal,
	BCard, BRow, BCol, BFormInput, BTable, BTr, BTh, BLink,
	BBadge, BDropdown, BDropdownItem, BPagination,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import { ref, watch, computed } from '@vue/composition-api'
import { useToast } from 'vue-toastification/composition'
import { numberFormat, formatDateTime } from '@core/utils/filter'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import withdrawalStoreModule from '@/views/payments/withdrawal/withdrawalStoreModule'
import store from '@/store'
import router from '@/router'

export default {
	components: {
		BButton,
		BModal,
		BCard,
		BRow,
		BCol,
		BFormInput,
		BTable,
		BTr,
		BTh,
		BLink,
		BBadge,
		BDropdown,
		BDropdownItem,
		BPagination,
		vSelect,
	},
	directives: {
		'b-modal': VBModal,
		Ripple,
	},
	props: {
		total: {
			type: Object,
			required: true,
		},
		fromDate: {
			type: String,
			required: true,
		},
		toDate: {
			type: String,
			required: true,
		},
	},
	setup(props) {
		const toast = useToast()
		const perPage = ref(20)
		const totalaccountStatementTotalWithdrawal = ref(0)
		const currentPage = ref(1)
		const perPageOptions = [10, 25, 50, 100]
		const sortBy = ref('id')
		const isSortDirDesc = ref(true)
		const fromdateFilter = ref(null)
		const todateFilter = ref(null)

		const refAccountStatementTotalWithdrawal = ref(null)

		if (!store.hasModule('payment-withdrawal')) store.registerModule('payment-withdrawal', withdrawalStoreModule)

		const tableColumns = [
			{ key: 'index', label: '#' },
			{ key: 'ref' },
			{ key: 'username' },
			{ key: 'status' },
			{ key: 'paymentType', label: 'Payment Type' },
			{ key: 'currency' },
			{ key: 'amount', label: 'Gross Amount' },
			{ key: 'netAmount', label: 'Charge Amount' },
			{ key: 'createdAt', label: 'Created At'},
			{ key: 'updatedAt', label: 'Updated At' },
		]

		const refetchData = () => {
			refAccountStatementTotalWithdrawal.value.refresh()
		}

		watch([currentPage, perPage, fromdateFilter, todateFilter], () => {
			refetchData()
		})

		const accountStatementTotalWithdrawalDetail = (ctx, callback) => {
			store
				.dispatch('payment-withdrawal/fetchWithdrawals', {
					perPage: perPage.value,
					page: currentPage.value,
					sortBy: sortBy.value,
					sortDesc: isSortDirDesc.value,
					userId: router.currentRoute.params.id,
					fromdate: props.fromDate,
					todate: props.toDate,
				})
				.then(response => {

					const { withdrawals, total, pageSize } = response

					callback(withdrawals)
					totalaccountStatementTotalWithdrawal.value = total
					perPage.value = pageSize
				})
				.catch(() => {
					toast({
						component: ToastificationContent,
						props: {
							title: 'Error fetching withdrawals list',
							icon: 'AlertTriangleIcon',
							variant: 'danger',
						},
					})
				})
		}

		const dataMeta = computed(() => {
			const localItemsCount = refAccountStatementTotalWithdrawal.value ? refAccountStatementTotalWithdrawal.value.localItems.length : 0
			return {
				from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
				to: perPage.value * (currentPage.value - 1) + localItemsCount,
				of: totalaccountStatementTotalWithdrawal.value,
			}
		})

		const resolveStatus = status => {
			if (status === 0) return { label: 'fail', variant: 'danger' }
			if (status === 1) return { label: 'successfull', variant: 'success' }
			return { label: 'none', variant: 'secondary' }
		}

		const resolveWithdrawalStatusVariant = stat => {
			if (stat === 1) return 'warning'
			if (stat === 2) return 'success'
			if (stat === 3) return 'danger'
			if (stat === 4) return 'primary'
			if (stat === 5) return 'info'
			if (stat === 6) return 'danger'
			if (stat === 7) return 'warning'
			return 'danger'
		}

		const resolveWithdrawalStatusStyle = stat => {
			if (stat === 1) return 'init'
			if (stat === 2) return 'success'
			if (stat === 3) return 'fail'
			if (stat === 4) return 'pending'
			if (stat === 5) return 'request'
			if (stat === 6) return 'reject'
			if (stat === 7) return 'request process admin'
			return 'None'
		}

		return {
			tableColumns,
			perPage,
			currentPage,
			totalaccountStatementTotalWithdrawal,
			dataMeta,
			perPageOptions,
			sortBy,
			isSortDirDesc,
			refAccountStatementTotalWithdrawal,

			accountStatementTotalWithdrawalDetail,

			resolveStatus,
			resolveWithdrawalStatusVariant,
			resolveWithdrawalStatusStyle,

			numberFormat,
			formatDateTime,
		}
	},
}
</script>